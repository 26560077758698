import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { FaBookmark } from "react-icons/fa"
import { IoIosCloseCircleOutline } from "react-icons/io"
import firebase from "../components/firebase/index"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { FaRegImage, FaVideo } from "react-icons/fa"
import useGlobalState from "../utils/useGlobalState"

const BookmarkPage = ({ location }) => {
  const [state] = useGlobalState()
  const data = useStaticQuery(BOOKMARKS_CONTENT)
  const lockedOutMessage =
    data?.wordpressAcfOptions?.options?.locked_out_message
      ?.bookmarks_page_message
  var { product_id, front_end_url, free } = data?.wordpressAcfOptions?.options

  const remove = block => {
    var uid = state?.user?.id
    var doc = firebase.firestore().doc(`user-favs/${uid}`)
    var { arrayRemove } = firebase.firestore.FieldValue

    doc.set({ [product_id]: arrayRemove(block) }, { merge: true })
  }

  return (
    <Layout
      seo={{ title: "Bookmarks" }}
      mainHeader={"Bookmarks"}
      mainContent={
        <Wrap>
          <Title>Your Bookmarks</Title>
          {state && state.hasAccess && state?.user ? (
            state?.favs?.length > 0 ? (
              <Items>
                {state?.favs?.map((block, idx) => {
                  var { id, value, path, type } = block
                  return (
                    <Item key={id}>
                      <Left as={Link} to={`${path}#${id}`}>
                        <FaBookmark color="#FF0000" />
                        <div>
                          {type === "image" && <FaRegImage color="#777" />}
                          {type === "video" && <FaVideo color="#777" />}
                          <span dangerouslySetInnerHTML={{ __html: value }} />
                        </div>
                      </Left>
                      <IoIosCloseCircleOutline
                        size="24px"
                        className="close"
                        onClick={() => remove(block)}
                      />
                    </Item>
                  )
                })}
              </Items>
            ) : (
              <p className="message info">
                You have not marked any headings, images or videos as bookmarks
                yet. When you are reading an article, click on the bookmark next
                to a heading, image or video.
              </p>
            )
          ) : (
            <>
              <div
                className="message info centered"
                dangerouslySetInnerHTML={{ __html: lockedOutMessage }}
              />
              <LinksRow>
                <a
                  className="link"
                  href={`${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${data?.wordpressAcfOptions?.options?.product_id}&redirect-url=${front_end_url}`}
                >
                  {free ? "Sign Up" : "Purchase"}
                </a>
                <Link to="/login/" className="link">
                  Log in
                </Link>
              </LinksRow>
            </>
          )}
        </Wrap>
      }
    />
  )
}

const Wrap = styled.div`
  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`
const LinksRow = styled.div`
  text-align: center;
  .link {
    color: #1a91ff;
    border-bottom: 2px solid #1a91ff;
    text-decoration: none;
    transition: all 200ms ease-in-out;
    font-size: 0.9rem;
    margin-right: 22px;
    &:hover {
      border-bottom: 2px solid #2e6ed7;
      color: #2e6ed7;
    }
  }
`

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  line-height: 1.2;
`

const Items = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 786px) {
    grid-template-columns: 1fr;
  }
`

const Left = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`
const Item = styled.div`
  border-radius: 4px;
  background: #e8ecec;
  border: 1px solid #d4d8d8;
  color: #47565a;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 35px;
  align-items: center;
  a {
    color: #47565a;
    text-decoration: none;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
      color: #202729;
    }
  }
  .close {
    cursor: pointer;
    justify-self: flex-end;
    transition: 0.2s all ease-in-out;
    margin: 0;
    width: 25px;
    &:hover {
      transform: scale(1.3);
    }
  }
`

export default BookmarkPage

const BOOKMARKS_CONTENT = graphql`
  {
    wordpressAcfOptions {
      options {
        product_id
        front_end_url
        free
        locked_out_message {
          bookmarks_page_message
        }
      }
    }
  }
`
